import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Motion, spring } from 'react-motion'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'

import apis from 'browser/app/models/apis'
import { Entity } from 'shared-libs/models/entity'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Text } from 'browser/mobile/components/text/text'
import { ISignatureFieldProps } from 'browser/components/atomic-elements/molecules/fields/esignature-field/esignature-field'
import { FramesManager } from 'shared-libs/components/view/frames-manager'

export interface ISignatureDrawingProps {
  lineWidth?: number
  strokeStyle?: string
}

export interface ISignaturePadProps extends Partial<ISignatureFieldProps>, ISignatureDrawingProps {
  onSigned: (signature: any, localFileUri: string) => void
  onClose?: () => void
}

type Point = {
  x: number
  y: number
}

type DrawingInfo = {
  lastPoint?: Point
  hasData?: boolean
  isLandscape?: boolean
  lineWidth?: number
  strokeStyle?: string
}

export const SignaturePad: React.FC<ISignaturePadProps> = (props: ISignaturePadProps) => {
  const { entity, frames, lineWidth, strokeStyle, onSigned, onClose } = props
  const infoRef = useRef<DrawingInfo>({ lineWidth, strokeStyle })
  const contextRef = useRef<CanvasRenderingContext2D>()
  const bottomToolbarRef = useRef<HTMLDivElement>()
  const topToolbarRef = useRef<HTMLDivElement>()
  const containerRef = useRef<HTMLDivElement>()
  const canvasRef = useRef<HTMLCanvasElement>()
  const rotatedRef = useRef<HTMLDivElement>()
  const rootRef = useRef<HTMLDivElement>()
  const [animation, setAnimation] = useState({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  })

  const handleInteraction = useCallback((event) => {
    draw(canvasRef.current, contextRef.current, event, infoRef.current, setAnimation)
  }, [])

  const handleClear = useCallback(() => {
    setAnimation({
      from: {
        opacity: 0,
      },
      to: {
        opacity: spring(1),
      },
    })
    infoRef.current.hasData = false
    setCanvasStyle(contextRef.current, infoRef.current)
    fillCanvas(canvasRef.current, contextRef.current)
  }, [])

  const handleDone = useCallback(async () => {
    if (!infoRef.current.hasData) {
      onClose()
      return
    }
    const blob = await toBlob(canvasRef.current)
    if (_.isNil(blob)) {
      return
    }
    commitBlob(frames, entity, blob, onSigned)
    onClose()
  }, [])

  const handleResize = _.debounce(() => {
    if (!canvasRef.current) {
      return
    }

    // update the rotated div to match the appropriate dimension of its parent
    const parent = rootRef.current
    const isLandscapeOrientation = isLandscape()
    const width = isLandscapeOrientation ? parent.clientHeight : parent.clientWidth
    const height = isLandscapeOrientation ? parent.clientWidth : parent.clientHeight
    rotatedRef.current.style.width = height + 'px'
    rotatedRef.current.style.height = width + 'px'

    // save pixels to offscreen canvas, to cover the case of another resize when
    // the user lifts their finger on the first stroke, which clears the canvas
    // (TODO: eagerly force all resizes on mobile devices to pre-empt resizes
    // upon touch events)
    const offscreenCanvas = document.createElement('canvas')
    offscreenCanvas.width = canvasRef.current.width
    offscreenCanvas.height = canvasRef.current.height
    const offscreenCtx = offscreenCanvas.getContext('2d')
    offscreenCtx.drawImage(canvasRef.current, 0, 0)

    resizeCanvas(canvasRef.current, rotatedRef.current, containerRef.current, topToolbarRef.current, bottomToolbarRef.current)
    setCanvasStyle(contextRef.current, infoRef.current)
    fillCanvas(canvasRef.current, contextRef.current)

    // restore pixels
    contextRef.current.drawImage(offscreenCanvas, 0, 0)
  }, 100)

  useEffect(() => {
    contextRef.current = canvasRef.current.getContext('2d')

    handleResize()

    // request fullscreen, if supported
    rootRef.current.requestFullscreen?.().then(handleResize)
    infoRef.current.isLandscape = isLandscape()

    // listen for container resize
    const observer = new ResizeObserver(handleResize)
    observer.observe(containerRef.current)

    // listen for window resize
    window.addEventListener('resize', handleResize)

    // listen for orientation
    const portraitMediaQuery = window.matchMedia('(orientation: portrait)')
    const portraitListener = (event: MediaQueryListEvent) => {
      infoRef.current.isLandscape = !event.matches
      handleResize()
    }
    portraitMediaQuery.addEventListener('change', portraitListener)

    const landscapeMediaQuery = window.matchMedia('(orientation: landscape)')
    const landscapeListener = (event: MediaQueryListEvent) => {
      infoRef.current.isLandscape = event.matches
      handleResize()
    }
    landscapeMediaQuery.addEventListener('change', landscapeListener)

    return () => {
      observer.disconnect()
      window.removeEventListener('resize', handleResize)
      portraitMediaQuery.removeEventListener('change', portraitListener)
      landscapeMediaQuery.removeEventListener('change', landscapeListener)
    }
  }, [])

  return (
    <div ref={rootRef} className="bg-white w-100 h-100">
      <div ref={rotatedRef} className="flex flex-column landscape-signature pa2">
        <div ref={topToolbarRef} className="row">
          <Button
            className="ml2"
            onClick={() => {
              props.onClose()
            }}
          >
            <Icon icon={IconNames.CROSS} />
          </Button>
          <Text className="flex-grow pr5 tc f4">Signature</Text>
        </div>
        <div ref={containerRef} className="flex-grow ba b--silver br2 pa1 mv2 row">
          <div className="flex overflow-hidden w-100 h-100">
            <canvas
              ref={canvasRef}
              onTouchStart={handleInteraction}
              onTouchEnd={handleInteraction}
              onTouchMove={handleInteraction}
              onMouseDown={handleInteraction}
              onMouseUp={handleInteraction}
              onMouseMove={handleInteraction}
              onContextMenu={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              className="br2"
            />
            <Motion defaultStyle={animation.from} style={animation.to}>
              {(style) => (
                <div className="empty-signature" style={style}>
                  Please sign here
                </div>
              )}
            </Motion>
          </div>
        </div>
        <div ref={bottomToolbarRef} className="row mb2">
          <Button className="flex-grow ml3 mr2 mobile-button" onClick={handleClear}>
            Clear Signature
          </Button>
          <Button
            className={classNames('flex-grow mr3 mobile-button', Classes.INTENT_PRIMARY)}
            onClick={handleDone}
          >
            Done Signing
          </Button>
        </div>
      </div>
    </div>
  )
}

SignaturePad.defaultProps = {
  lineWidth: 4,
  strokeStyle: 'black',
}

function draw(
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
  event: React.MouseEvent | React.TouchEvent,
  drawingInfo: DrawingInfo,
  setAnimation: (animation) => void
) {
  event.preventDefault()
  event.stopPropagation()

  const eventData = (event as React.TouchEvent)?.touches?.[0] ?? (event as React.MouseEvent)

  if (event.type === 'touchstart' || event.type === 'mousedown') {
    drawingInfo.lastPoint = getPoint(canvas, eventData, drawingInfo)
  } else if (event.type === 'touchend' || event.type === 'mouseup') {
    drawingInfo.lastPoint = null
  } else if (drawingInfo.lastPoint) {
    const point = getPoint(canvas, eventData, drawingInfo)
    context.beginPath()
    context.moveTo(drawingInfo.lastPoint.x, drawingInfo.lastPoint.y)
    context.lineTo(point.x, point.y)
    context.stroke()
    if (!drawingInfo.hasData) {
      setAnimation({
        from: {
          opacity: 1,
        },
        to: {
          opacity: spring(0),
        },
      })
    }
    drawingInfo.hasData = true
    drawingInfo.lastPoint = point
  }
}

function fillCanvas(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
  context.fillRect(0, 0, canvas.width, canvas.height)
}

function getPoint(
  canvas: HTMLCanvasElement,
  eventData: React.Touch | React.MouseEvent,
  drawingInfo: DrawingInfo
): Point {
  const bounds = canvas.getBoundingClientRect()
  const ex = eventData.pageX - bounds.left - scrollX
  const ey = eventData.pageY - bounds.top - scrollY

  if (drawingInfo.isLandscape) {
    return { x: ex, y: ey }
  } else {
    // portrait is rotated in css, so transform screen coords to canvas coordinate space
    return { x: ey, y: canvas.height - ex }
  }
}

function resizeCanvas(
  canvas: HTMLCanvasElement,
  outerContainer: HTMLDivElement,
  innerContainer: HTMLDivElement,
  topToolbar: HTMLDivElement,
  bottomToolbar: HTMLDivElement
) {
  const outerContainerStyle = window.getComputedStyle(outerContainer)
  const innerContainerStyle = window.getComputedStyle(innerContainer)
  const verticalOffset =
    topToolbar.offsetHeight +
    bottomToolbar.offsetHeight +
    parseFloat(outerContainerStyle.paddingTop) +
    parseFloat(outerContainerStyle.paddingBottom) +
    parseFloat(innerContainerStyle.paddingTop) +
    parseFloat(innerContainerStyle.paddingBottom) +
    parseFloat(innerContainerStyle.marginTop) +
    parseFloat(innerContainerStyle.marginBottom)

  canvas.width = outerContainer.offsetWidth
  canvas.height = outerContainer.offsetHeight - verticalOffset
}

function setCanvasStyle(context: CanvasRenderingContext2D, drawingInfo: DrawingInfo) {
  context.strokeStyle = drawingInfo.strokeStyle
  context.fillStyle = 'white'
  context.lineWidth = drawingInfo.lineWidth
  context.lineJoin = 'round'
  context.lineCap = 'round'
}

function addMultipartFile(entity: Entity, path: string, uniqueId: string, file: File) {
  const signaturePath = `${path}.signature`
  entity.clearMultipartFiles(signaturePath)
  entity.addMultipartFiles(signaturePath, [{ file, uniqueId }])
}

function makeSignature(fileName: string, uniqueId: any) {
  const user = apis.getSettings().getUser()
  const firm = apis.getSettings().getFirm()
  return {
    signatureType: 'pad',
    signed: true,
    signer: user.displayName,
    signedBy: {
      user: {
        entityId: user.uniqueId,
      },
      firm: {
        entityId: firm.uniqueId,
      },
    },
    signature: {
      name: `${fileName}.png`,
      type: 'image',
      uniqueId,
    },
    signedDate: moment().toISOString(),
  }
}

function commitBlob(
  frames: FramesManager,
  entity: any,
  blob: Blob,
  onSigned: (signature: any, localFileUri: string) => void
) {
  const valuePath = frames.getContext('valuePath').join('.')
  const signerName = apis.getSettings().getUser().displayName
  const fileName = _.replace(signerName, ' ', '')
  const uniqueId = uuidv4()
  const file = new File([blob], `${fileName}.png`, { type: 'image/png' })
  const objectUri = URL.createObjectURL(file)
  addMultipartFile(entity, valuePath, uniqueId, file)
  onSigned(makeSignature(fileName, uniqueId), objectUri)
}

function isLandscape(): boolean {
  const orientation = getOrientation()
  return orientation === 'landscape-primary' || orientation === 'landscape-secondary'
}

function getOrientation(): OrientationType {
  return (
    screen.orientation?.type ||
    screen['mozOrientation'] ||
    screen['msOrientation'] ||
    (window.innerHeight < window.innerWidth ? 'landscape-primary' : 'portrait-primary')
  )
}

function toBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob)
    })
  })
}
