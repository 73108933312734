import _ from "lodash"
import { v4 as uuidv4 } from 'uuid'

import { evaluateExpression } from "shared-libs/helpers/evaluation"
import { CustomFormulas } from "shared-libs/helpers/formulas"
import { EntityProxy } from "shared-libs/models/proxy"

import apis from "browser/app/models/apis"
import { ConfirmationModal } from "browser/components/atomic-elements/organisms/confirmation-modal"

enum ContextType {
  BULK = 'bulk',
  SINGLE = 'single',
}

interface Mapping {
  destination: string
  value: string
  formula: string // formula
  shouldApply: boolean
}

export class Action extends EntityProxy {
  title: string
  contexts: ContextType[]
  isHamburger: boolean
  iconClass: string
  entitiesToEdit: string // formula
  skipValidation: boolean
  outputMappings: Mapping[]
  confirmationTitle: string
  confirmationText: string
  __reactKey: string // simplest way to appease the React render gods

  constructor(data) {
    super(data)
    this.registerOwnProperties()
    this.__reactKey = uuidv4()
  }

  public get isBulk() {
    return _.includes(this.contexts, ContextType.BULK)
  }

  public get isSingle() {
    return _.includes(this.contexts, ContextType.SINGLE)
  }

  public process = async (entities) => {
    const { confirmationTitle, confirmationText } = this
    const _process = () => this._process(entities)

    if (confirmationTitle || confirmationText) {
      ConfirmationModal.open({
        onPrimaryClicked: _process,
        confirmationTitle,
        confirmationText,
      })
    } else {
      return _process()
    }
}

  public _process = async (entities) => {
    return Promise.all(entities.map(async entity => {
      const entitiesToEdit = this.entitiesToEdit ?
        await apis.getStore().findRecords(this.eval({ entity }, this.entitiesToEdit)) :
        [entity]

      return Promise.all(entitiesToEdit.map(nestedEntity => {
        this.outputMappings.forEach(m => {
          const shouldApply = m.shouldApply ? this.eval({ nestedEntity }, m.shouldApply) : true

          if (!shouldApply) {
            return
          }

          const value = m.formula ? this.eval({ nestedEntity }, m.formula) : m.value
          nestedEntity.set(m.destination, value)
        })

        return nestedEntity.save({ skipValidation: this.skipValidation })
      }))
    }))
  }

  public eval(additionalProps, formula) {
    const settings = apis.getSettings()

    return evaluateExpression(
      { settings, ...CustomFormulas, ...additionalProps },
      formula
    )
  }
}
