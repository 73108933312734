import React from "react";
import _ from 'lodash'
import { HelpBlock } from "../../atoms/help-block/help-block";
import { EntitySelectField } from "../../molecules/fields/select-field/entity-select-field";

interface TrailerWarningProps {
  entity: any
  previewEntity: any
}

export class TrailerWarning extends React.Component<TrailerWarningProps, any> {
  public render() {
    return (
      <>
        {this.renderTrailerStatusWarning()}
        {this.renderExistingYardMoveWarning()}
      </>
    )
  }

  public renderTrailerStatusWarning() {
    const { previewEntity } = this.props
    const condition = previewEntity.core_yms_trailer?.condition

    if (condition === 'In Service') {
      return null
    }

    return <>
        <HelpBlock
          helpText={`Trailer Issue: ${condition}`}
          style={{
            "backgroundColor": "#ffccbc",
            "color": "#bf360c",
            "margin": 0,
            "padding": 10,
            "fontSize": 14,
            "textAlign": "center"
          }}
        />
      </>
  }

  public renderExistingYardMoveWarning() {
    const { entity, previewEntity } = this.props
    const yardMoves = previewEntity?.core_yms_trailer?.activeYardMoves || []
    const filteredYardMoves = yardMoves.filter(ym =>
      entity?.core_yms_yardMove?.previousYardMove?.entityId != ym.yardMove.entityId)

    if (_.isEmpty(filteredYardMoves)) {
      return null
    }

    return (
      <>
        <HelpBlock
          helpText="Warning: Selected trailer tied to pending yard move."
          style={{
            "backgroundColor": "#ffccbc",
            "color": "#bf360c",
            "margin": 0,
            "padding": 10,
            "fontSize": 14,
            "textAlign": "center"
          }}
        />
        { filteredYardMoves.map(ym => (
          <EntitySelectField
            value={ym.yardMove}
            showLinkIcon={true}
            isDisabled={true}
            showInlinePreview={true}
            key={ym.yardMove.entityId}
            entityType={"/1.0/entities/metadata/core_yms_yardMove.json"}
          />
        )) }
      </>
    )
  }
}
