import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/loading-spinner/_loading-spinner.scss'

interface ILoadingSpinnerProps extends IBaseProps {
  spinnerClassName?: string
  color?: boolean
  size?: string
  label?: string
}

export const LoadingSpinner: React.FC<ILoadingSpinnerProps> = (props) => {
  const { className, color, size, spinnerClassName, label } = props
  const colorClass = color ? 'c-loadingSpinner--' + color : ''
  const sizeClass = size ? 'c-loadingSpinner--' + size : ''
  return (
    <div className={classNames('c-loadingSpinner-container', className)}>
      <div
        className={classNames('c-loadingSpinner', colorClass, sizeClass, spinnerClassName)}
        data-debug-id='loading-spinner'
      />
      <div>{label}</div>
    </div>
  )
}
