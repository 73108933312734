import { UICheckboxField } from 'browser/components/ui-fields/ui-checkbox'
import { UIView } from 'browser/components/json-elements/atoms/view'
import { UIConditional } from 'browser/components/json-elements/atoms/conditional'
import { Spacer } from 'browser/components/atomic-elements/atoms/spacer/spacer'
import { SignatureField } from 'browser/components/atomic-elements/molecules/fields/signature-field/signature-field'
import { QRCodeField } from 'browser/components/atomic-elements/molecules/fields/qrcode-field'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { If } from 'browser/components/atomic-elements/atoms/if'
import { Separator } from 'browser/components/atomic-elements/atoms/separator'
import { ImageLoader } from 'browser/components/atomic-elements/atoms/image-loader/image-loader'
import { Text } from './text/text'
import { MobileEntityDetails } from './entity-details/entity-details'
import { MobileForm } from './form/form'
import { WorkflowManager } from 'browser/components/containers/workflow-manager'
import { MobileFileField } from './file-field/mobile-file-field'
import { MobileGeolocationField } from './geolocation-field/mobile-geolocation-field'
import { MobileInputField } from './input/mobile-input-field'
import { MobileLabel } from './label/mobile-label'
import { WorkflowButton } from './workflow-button/workflow-button'
import { MobileSelectField } from './select/mobile-select-field'
import { MobileESignatureField } from './signature/mobile-signature-field'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { MobileBarcodeField } from './barcode/barcode-field'
import { Barcode } from 'browser/components/atomic-elements/atoms/barcode/barcode'
import { OcrCameraField } from './ocr-field/ocr-camera-field'
import { FormulaField } from 'browser/components/atomic-elements/molecules/fields/formula-field'
import { JSONInputFactory } from 'browser/components/json-elements/higher-order-components/json-input-factory'
import { MobileSwitch } from './switch/mobile-switch'
import { MobileList } from './list/mobile-list'
import { ProgressBar } from './progress-bar/progress-bar'
import { UploadProgress } from './upload-progress'
import { PermissionStatusProvider } from 'browser/contexts/permission-status/permission-provider'
import { AttachmentViewer } from 'browser/components/atomic-elements/atoms/attachment-viewer/attachment-viewer'
import { DateField } from 'browser/components/atomic-elements/molecules/fields/date-field'
import {
  RadioButtonGroup,
  RadioButtonGroupField,
} from './radio-button/radio-button'
import { JSONSelectFactory } from 'browser/components/json-elements/higher-order-components/json-select-factory'

const UIFormulaField = JSONInputFactory(FormulaField)
const UIRadioButtonGroup = JSONSelectFactory(RadioButtonGroup)
const UIRadioButtonGroupField = JSONSelectFactory(RadioButtonGroupField)

export default {
  'ui:dateField': DateField,
  'ui:attachmentViewer': AttachmentViewer,
  'ui:spacer': Spacer,
  'ui:checkboxField': UICheckboxField,
  'ui:view': UIView,
  'ui:conditional': UIConditional,
  'ui:signatureField': SignatureField,
  'ui:eSignatureField': MobileESignatureField,
  'ui:switch': MobileSwitch,
  'ui:qrCodeField': QRCodeField,
  'ui:barcode': Barcode,
  'ui:barcodeField': MobileBarcodeField,
  'ui:image': ImageLoader,
  'ui:selectField': MobileSelectField,
  'ui:inputField': MobileInputField,
  'ui:fileField': MobileFileField,
  'ui:list': MobileList,
  'ui:section': Section,
  'ui:geolocationField': MobileGeolocationField,
  'ui:form': MobileForm,
  'ui:entityDetails': MobileEntityDetails,
  'ui:ocrCameraField': OcrCameraField,
  'ui:radioButtonGroup': UIRadioButtonGroup,
  'ui:radioButtonGroupField': UIRadioButtonGroupField,
  'ui:text': Text,
  'ui:workflowManager': WorkflowManager,
  'ui:label': MobileLabel,
  'ui:helpBlock': HelpBlock,
  'ui:if': If,
  'ui:separator': Separator,
  'ui:workflowButton': WorkflowButton,
  'ui:formulaField': UIFormulaField,
  'ui:progressBar': ProgressBar,
  'ui:uploadProgress': UploadProgress,
  'ui:permissionStatusProvider': PermissionStatusProvider,
}
