import { Classes, Icon, Position, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { ISelectProps, Select } from 'browser/components/atomic-elements/atoms/select'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
// tslint:disable-next-line:max-line-length
import { EntityAssociationsSheet } from 'browser/components/atomic-elements/organisms/entity/entity-associations/entity-associations-sheet'
import { EntityFormSheet } from 'browser/components/atomic-elements/organisms/entity/entity-form-sheet'
// tslint:disable-next-line:max-line-length
import { EntityPreview } from 'browser/components/atomic-elements/organisms/entity/entity-preview/entity-preview'
import { Entity } from 'shared-libs/models/entity'
import { ALL, Query } from 'shared-libs/models/query'
import { SheetContext } from '../sheet/sheet-manager'
import { Store } from 'shared-libs/models/store'
import { isUUIDValid } from 'shared-libs/helpers/utils'
import { ComponentsContext } from 'browser/contexts/components/components-context'
import { EntityMapper, ISimpleMapping, toIMapping } from 'shared-libs/models/entity-mapping'

const DEFAULT_ORDERS = [
  {
    path: 'precomputation.displayName',
    type: 'ascending',
  },
]

export interface Filter {
  type: string
  path: string
  value?: any
  values?: any[]
}

/**
 * @uiComponent
 */
export interface IEntitySelectProps extends ISelectProps {
  addInflationSessionId?: (string) => void
  edgeToOptionCreator?: (edge: any, schema: any) => any
  entityToEdgeCreator?: (entity: any, schema: any, denormalizedProperties: any[]) => any
  removeInflationSessionId?: (string) => void
  onOptionsChange?: (...args: any[]) => {}

  defaultNewEntityValue?: any
  defaultNewEntityInputValuePath?: string
  denormalizedProperties?: any[]
  entity?: any
  entityType: string
  filter?: Filter // deprecated
  filters?: Filter[]
  orders?: any[]
  isCreatable?: boolean
  newOptionAssociationProps?: any
  newOptionMappings?: ISimpleMapping[]
  showLinkIcon?: boolean
  showInlinePreview?: boolean
  tetherOptions?: object
  openOverlay?: any
  optionHeight?: number
  returnValueAsEdge?: boolean
  queryPath?: string
  queryPaths?: string[]
  shouldPreloadEntity?: boolean

  runQueryOnPropsUpdate?: boolean
  entityCreateSchema?: string
  preloadOptions?: boolean
}

interface IEntitySelectState {
  inputValue: string
  isLoading: boolean
  options: any[]
  value: any
}

class EntitySelect extends React.Component<IEntitySelectProps, IEntitySelectState> {
  public static defaultProps: Partial<IEntitySelectProps> = {
    edgeToOptionCreator: defaultEdgeToOptionCreator,
    entityToEdgeCreator: defaultEntityToEdgeCreator,
    isCreatable: true,
    optionLabelPath: 'displayName',
    optionValuePath: 'uniqueId',
    returnValueAsEdge: true,
    showLinkIcon: true,
    tetherOptions: {
      attachment: 'top right',
      targetAttachment: 'bottom right',
    },
    removeOptionText: "( Unlink Entity )",
    entityCreateSchema: "uiSchema.web.entityCreationSheet",
  }

  static contextType = ComponentsContext
  context!: React.ContextType<typeof ComponentsContext>

  public collection: any
  private entitySchema: any
  private input: Select
  private store: Store
  private tether: TetherTarget
  private isOptionsLoaded: boolean
  private loadingPromise: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.entitySchema = this.store.getRecord(props.entityType)
    const { edgeToOptionCreator, options, value, shouldPreloadEntity } = props
    this.state = {
      inputValue: undefined,
      isLoading: false,
      options: options || [],
      value: edgeToOptionCreator(value, this.entitySchema),
    }
    this.isOptionsLoaded = false

    if (shouldPreloadEntity && value?.entityId) {
      this.store.findRecord(value.entityId).then((entity) => {
        this.handleChange(entity.uniqueId, entity)
      })
    }

    this.handleInputChanged = _.debounce(this.handleInputChanged, 200)
  }

  public async componentDidMount() {
    await this.initializeCollection(this.props)

    if (this.props.preloadOptions === true) {
      this.handleInputChanged(null)
    }
  }

  private async initializeCollection(props): Promise<any> {
    this.collection?.cancel()

    if (!this.store.getRecord(props.entityType)) {
      // resolve missing metadata
      await this.store.findSchemas([props.entityType])
    }

    const query = new Query(apis).setEntityType(props.entityType).setOrders(DEFAULT_ORDERS)
    if (props.queryPaths) {
      query.setQueryPaths(props.queryPaths)
    } else {
      query.setQueryPath(props.queryPath)
    }
    if (props.filter) {
      const filter = props.filter
      query.setFilters([filter])
    }
    if (props.orders) {
      query.setOrders(props.orders)
    }
    query.setDebug({ context: `input--EntitySelect--${props['data-debug-id']}` })
    this.collection = query.getCollection()
  }

  public async UNSAFE_componentWillReceiveProps(nextProps) {
    const clearOptionsAndMaybeReload = () => {
      this.loadingPromise?.cancel()
      this.loadingPromise = undefined

      this.setState({
        options: [],
      })
      this.isOptionsLoaded = false

      if (this.props.preloadOptions) {
        this.handleInputChanged(null)
      }
    }

    // IMPORTANT: we need to reset options and value when filter changed
    if (!_.isEqual(this.props.filter, nextProps.filter)) {
      clearOptionsAndMaybeReload()
    }
    if (!_.isEqual(this.props.filters, nextProps.filters)) {
      clearOptionsAndMaybeReload()
    }

    // reset selected value if value changed
    if (!_.isEqual(this.props.value, nextProps.value)) {
      const { edgeToOptionCreator, value, shouldPreloadEntity } = nextProps

      if (shouldPreloadEntity && value?.entityId) {
        const entity = await this.store.findRecord(value.entityId)
        this.handleChange(entity.uniqueId, entity)
      }

      this.setState({
        value: edgeToOptionCreator(value, this.entitySchema),
      })
    }
    // reset the collection when the entityType changes
    const resetFn = () => {
      if (this.props.runQueryOnPropsUpdate) {
        this.handleInputChanged(null)
      }
    }
    if (!_.isEqual(this.props.entityType, nextProps.entityType)) {
      this.initializeCollection(nextProps).then(resetFn)
    } else {
      resetFn()
    }
  }

  public componentWillUnmount() {
    this.collection?.cancel()
    this.collection?.dispose()
    this.loadingPromise?.cancel()
  }

  public focus() {
    this.input.focus()
  }

  public render() {
    const { showLinkIcon, showInlinePreview } = this.props
    if (showInlinePreview) {
      return this.renderSelect(this.renderEntityPageButton())
    }
    else if (showLinkIcon) {
      return this.renderSelect(this.renderInfoButton())
    }
    return this.renderSelect(null)
  }

  private renderSelect(children) {
    const { className, isCreatable, optionValuePath } = this.props
    const { isLoading, value } = this.state
    const optionsWithValue = this.getOptions()
    const creatableProps = {
      isCreatable,
      onNewOptionClick: this.handleNewOptionClick,
    }
    const handleRef = (ref) => {
      this.input = ref
    }

    const selectProps = {
      ...this.props
    }
    if (this.props.queryPath === ALL) {
      // when querying ES for full text search, we can't assume the user input query is shown in the option label
      selectProps.filterOption = () => true
    }

    return (
      <Select
        {...selectProps}
        {...creatableProps}
        className={classNames('flex flex-row', className)}
        isAsync={true}
        isLoading={isLoading}
        onChange={this.handleChange}
        onInputChange={this.handleInputChanged}
        onOpen={this.handleMenuOpened}
        options={optionsWithValue}
        ref={handleRef}
        value={_.get(value, optionValuePath, '')}
      >
        {children}
      </Select>
    )
  }

  private getOptions() {
    const {
      optionValuePath,
      optionLabelPath,
      showRemoveOption,
      removeOptionText,
    } = this.props

    const { inputValue, options, value } = this.state

    if (value) {
      const isValueInOptions = _.find(options, {
        [optionValuePath]: value[optionValuePath],
      })
      // add value to options if the following criterias are met
      // 1. if searchQuery does not exists
      // 2. value is not in option
      if (_.isEmpty(inputValue) && !isValueInOptions) {
        return [value].concat(options)
      }
    }

    return options
  }

  private getCollectionFilters() {
    const { filters, filter } = this.props
    if (
      filters &&
      filters.every((f) => f && (f.type === 'notExists' || f.value || !_.isEmpty(f.values)))
    ) {
      return filters
    } else {
      if (filter && (filter.type == 'notExists' || filter.value || !_.isEmpty(filter.values))) {
        return [filter]
      }
    }
    return []
  }

  private renderInfoButton() {
    const { size, tetherOptions } = this.props
    const { value } = this.state
    if (value) {
      return (
        <TetherTarget
          automaticAdjustOffset={true}
          tetherOptions={tetherOptions}
          tethered={this.renderEntityPreviewPopover()}
          ref={(ref) => {
            this.tether = ref
          }}
        >
          <Button
            className={classNames(
              'c-entitySelect-infoButton ',
              Classes.MINIMAL,
              Classes.iconClass(IconNames.INFO_SIGN)
            )}
            onClick={this.handleInfoButtonClick}
            size={size}
          />
        </TetherTarget>
      )
    }
  }

  private handleEntityPageClick = () => {
    const { value } = this.state
    const id = _.get(value, 'uniqueId')

    if (id) {
      window.open(`/entity/${id}`, '_blank')
    }
  }

  private renderEntityPageButton() {
    const { value } = this.state

    if (!value) {
      return
    }

    return (
      <Tooltip
        content='Open in new tab'
        position={Position.BOTTOM}
      >
        <Button
          className={Classes.MINIMAL}
          size='sm'
          onClick={this.handleEntityPageClick}
        >
          <Icon
            icon={IconNames.SHARE}
          />
        </Button>
      </Tooltip>
    )
  }

  private renderEntityPreviewPopover() {
    const { value } = this.props
    return <EntityPreview
      value={value}
      renderAsPopover={true}
      components={this.context.components}
    />
  }

  /****************************************************************************/
  // Entity Creation
  /****************************************************************************/

  private createNewEntity(entitySchema, defaultValue) {
    const { openOverlay, entityCreateSchema } = this.props
    openOverlay(
      <EntityFormSheet
        defaultValue={defaultValue}
        entitySchema={entitySchema}
        onCreate={this.handleEntityCreated}
        uiSchemaPath={entityCreateSchema}
      />
    )
  }

  private createNewAssociateNewEntity(newOptionAssociationProps, entityEdge, defaultValue) {
    const { openOverlay } = this.props
    const { associatedEntityType, edgePath, entityType, isEdgeOnEntity } = newOptionAssociationProps
    const associatedEntitySchema = this.store.getRecord(associatedEntityType)
    const entitySchema = this.store.getRecord(entityType)
    this.store.findRecord(entityEdge.entityId).then((entity) => {
      openOverlay(
        <EntityAssociationsSheet
          associatedEntityDefaultValue={defaultValue}
          associatedEntitySchema={associatedEntitySchema}
          entity={entity}
          entitySchema={entitySchema}
          edgePath={edgePath}
          isEdgeOnEntity={isEdgeOnEntity}
          onChange={this.handleEntityCreated}
        />
      )
    })
  }

  /****************************************************************************/
  // Handlers
  /****************************************************************************/

  // tslint:disable-next-line:member-ordering
  public handleChange = (entityId, option) => {
    if (entityId) {
      const { entityToEdgeCreator, denormalizedProperties } = this.props
      const value = this.props.returnValueAsEdge
        ? entityToEdgeCreator(option, this.entitySchema, denormalizedProperties)
        : option
      this.props.onChange(value, option)
    } else {
      this.props.onChange(undefined)
    }
  }

  private handleEntityCreated = (entity: Entity) => {
    const { entityToEdgeCreator, denormalizedProperties } = this.props

    // It takes some time for some newly created entities to have their denormalized properties inflated.
    // We notify the parent entity that an inflation is pending so as to prevent saving until inflation is finished.
    const inflationSessionId = uuidv4()
    this.props.addInflationSessionId(inflationSessionId)

    entity
      .waitUntilIdle()
      .then(() => {
        const value = entityToEdgeCreator(entity, this.entitySchema, denormalizedProperties)
        this.props.onChange(value, entity)
      })
      .finally(() => {
        this.props.removeInflationSessionId(inflationSessionId)
      })
  }

  private handleMenuOpened = () => {
    const { options } = this.state
    if (_.isEmpty(options)) {
      this.handleInputChanged(null)
    }
  }

  private handleInputChanged = async (input) => {
    this.loadingPromise?.cancel()

    this.loadingPromise = this._handleInputChanged(input)
    return this.loadingPromise
  }

  private _handleInputChanged = async (input) => {
    input = input ? input.trim() : null

    // do not make remote request if input value didn't change
    if (this.isOptionsLoaded && input === this.state.inputValue) {
      return
    }

    // we also need to load the selected entity to get the name
    this.collection?.cancel()
    this.setState({ isLoading: true })

    // direct UUID lookup
    if (isUUIDValid(input)) {
      return apis.getStore().findRecord(input).then((record: Entity) => {
        this.setState(
          {
            inputValue: record.displayName,
            isLoading: false,
            options: [record],
          }
        )
        this.isOptionsLoaded = true
      })
    }

    this.setState({ isLoading: true })

    // fetch by UUID directly
    if (isUUIDValid(input)) {
      return apis.getStore().findRecord(input).then((record: Entity) => {
        this.setState(
          {
            inputValue: record.displayName,
            isLoading: false,
            options: [record],
          }
        )
        this.isOptionsLoaded = true
      }).catch((e) => {
        this.setState({
          isLoading: false,
          options: [],
        })
        this.isOptionsLoaded = true
      })
    }

    // we also need to load the selected entity to get the name
    this.collection.query.setQuery(input)
    this.collection.query.setFilters(this.getCollectionFilters())
    return this.collection.find().then((entities) => {
      this.setState({
        inputValue: input,
        isLoading: false,
        options: entities,
      })
      this.isOptionsLoaded = true

      const { onOptionsChange } = this.props
      onOptionsChange?.({ options: entities})
    })
  }

  private handleNewOptionClick = async (value: string) => {
    const {
      entityType,
      filter,
      newOptionAssociationProps,
      onNewOptionClick,
      defaultNewEntityValue,
      defaultNewEntityInputValuePath,
    } = this.props
    // if onNewOptionClick is provided, we will defer to the provided one
    if (onNewOptionClick) {
      return onNewOptionClick(value)
    }
    const entitySchema = this.store.getRecord(entityType)
    const recordTemplate = this.store.createRecord(entitySchema)
    const defaultValueFromTemplate = getNewEntityDefaultValue(
      recordTemplate,
      value,
      defaultNewEntityValue,
      defaultNewEntityInputValuePath
    )
    const defaultValue = await this.processMappings(defaultValueFromTemplate)

    if (newOptionAssociationProps && filter?.value) {
      const entityEdge = filter.value
      this.createNewAssociateNewEntity(newOptionAssociationProps, entityEdge, defaultValue)
    } else {
      this.createNewEntity(entitySchema, defaultValue)
    }
  }

  private async processMappings(defaultValue) {
    defaultValue = _.defaultTo(defaultValue, {})

    const { entity, entityType, newOptionMappings } = this.props
    if (_.isEmpty(newOptionMappings)) {
      return defaultValue
    }

    const iMappings = toIMapping(entity, newOptionMappings, defaultValue)
    const settings = entity?.getStore()?.api?.settings || {}
    const schemaId = entity?.getStore()?.getRecord(entityType)?.uniqueId
    await EntityMapper.create(apis, settings)
      .fromEntity(entity)
      .useMappings(iMappings)
      .to(defaultValue, [schemaId])
      .execute({
        settings,
      })

    return defaultValue
  }

  private handleInfoButtonClick = () => {
    const { showLinkIcon } = this.props
    const { value } = this.state
    // TODO(Peter): revisit when doing react-router@4.0 update
    if (value.uniqueId && showLinkIcon) {
      window.open(`/entity/${value.uniqueId}`, '_blank')
    }
  }
}

// TODO(Peter): we should generalize this and move this logic into the schemas
export function getNewEntityDefaultValue(
  entity,
  newOptionString,
  defaultValue = null,
  defaultInputValuePath = null
) {
  const schemaIds = _.map(entity.schemas, 'id')
  if (defaultValue == null) {
    if (_.includes(schemaIds, '/1.0/entities/metadata/business.json')) {
      return {
        business: { legalName: newOptionString },
      }
    } else if (_.includes(schemaIds, '/1.0/entities/metadata/location.json')) {
      return {
        location: { name: newOptionString },
      }
    } else if (_.includes(schemaIds, '/1.0/entities/metadata/person.json')) {
      const tokens = newOptionString.split(/\s+/)
      return {
        person: {
          firstName: tokens[0],
          lastName: tokens[1],
        },
      }
    } else if (_.includes(schemaIds, '/1.0/entities/metadata/core_yms_trailer.json')) {
      // TODO: really need to move this to schema
      return {
        core_yms_trailer: {
          name: newOptionString,
        },
      }
    }
  }
  if (defaultInputValuePath != null) {
    defaultValue = _.set(defaultValue, defaultInputValuePath, newOptionString)
  }
  return defaultValue
}

function defaultEntityToEdgeCreator(entity, schema, edgeDenormalizedProperties) {
  const properties = _.get(schema, 'metadata.denormalizedProperties') || []
  const allDenormalizedProps = _.uniq(properties.concat(edgeDenormalizedProperties || []))

  const denormalizedProperties = {}
  _.forEach(allDenormalizedProps, (path: string) => {
    denormalizedProperties[path] = _.get(entity, path)
  })

  return {
    denormalizedProperties,
    displayName: entity.displayName,
    entityId: entity.uniqueId,
  }
}

function defaultEdgeToOptionCreator(edge, schema) {
  if (!edge) {
    return
  }
  const option = {
    displayName: edge.displayName,
    uniqueId: edge.entityId,
  }
  const properties = _.get(edge, 'denormalizedProperties')
  _.forEach(properties, (value, path) => _.set(option, path, value))
  return option
}

export default React.forwardRef((props: IEntitySelectProps, ref: React.Ref<EntitySelect>) => (
  <SheetContext.Consumer>
    {({ openOverlay }) => <EntitySelect {...props} openOverlay={openOverlay} ref={ref} />}
  </SheetContext.Consumer>
))
