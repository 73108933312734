import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import React, { useContext, useEffect, useMemo, useReducer } from 'react'

export interface IMobileHeaderButtonState {
  handler: () => void
  className?: string
}

export interface IMobileHeaderContext {
  enabled?: boolean
  title?: React.ReactNode
  chatButton?: IMobileHeaderButtonState
}

interface IMobileHeaderContextEntry extends Partial<IMobileHeaderContext> {
  id: string

  /** Whether to continue searching in previous stack entries for an element. */
  shouldFallthrough?: boolean | Record<keyof Partial<IMobileHeaderContext>, boolean>
}

/* A wrapper for a stack of header entries, with convenience getters */
export class MobileHeader implements Readonly<IMobileHeaderContext> {
  public readonly stack: IMobileHeaderContextEntry[]

  public constructor(stack: IMobileHeaderContextEntry[] = []) {
    this.stack = stack
  }

  public get enabled(): boolean {
    return this.top('enabled')
  }

  public get title(): React.ReactNode {
    return this.top('title')
  }

  public get chatButton(): IMobileHeaderButtonState {
    return this.top('chatButton')
  }

  private top<K extends keyof IMobileHeaderContext>(key: K): IMobileHeaderContext[K] {
    for (let i = this.stack.length - 1; i >= 0; i--) {
      const entry = this.stack[i]
      const value = entry[key]
      if (value || value === null) {
        // allow null, as a signal to hide the component. Consumer could treat
        // falsy as a signal to use a default.
        return value
      }

      const shouldFallthrough = _.isBoolean(entry.shouldFallthrough)
        ? entry.shouldFallthrough
        : entry.shouldFallthrough?.[key]

      if (shouldFallthrough === false) {
        return undefined
      }
    }
  }
}

enum MobileHeaderActionType {
  Put = 'put',
  Delete = 'delete',
}

interface IMobileHeaderStackAction {
  type: MobileHeaderActionType
  entry: IMobileHeaderContextEntry
}

/*
 * Produces a new MobileHeader object given an action, to break referential
 * equality in the context provider.
 */
function stackReducer(header: MobileHeader, action: IMobileHeaderStackAction): MobileHeader {
  const stack = header.stack
  if (action.type === MobileHeaderActionType.Put) {
    const foundIndex = stack.findIndex((t) => t.id === action.entry.id)
    if (foundIndex === -1) {
      stack.push(action.entry)
    } else {
      stack[foundIndex] = action.entry
    }
  } else if (action.type === MobileHeaderActionType.Delete) {
    const indexToDelete = stack.findIndex((t) => t.id === action.entry.id)
    if (indexToDelete !== -1) {
      stack.splice(indexToDelete, 1)
    }
  }
  return new MobileHeader(stack)
}

const initialHeader = new MobileHeader([
  {
    id: uuidv4(),
    enabled: true,
  },
])

export const MobileHeaderContext = React.createContext<IMobileHeaderContext>(null)
export const MobileHeaderDispatchContext =
  React.createContext<React.Dispatch<IMobileHeaderStackAction>>(null)

export const MobileHeaderContextProvider: React.FC = (props) => {
  const [header, dispatch] = useReducer(stackReducer, initialHeader)
  return (
    <MobileHeaderContext.Provider value={header}>
      <MobileHeaderDispatchContext.Provider value={dispatch}>
        {props.children}
      </MobileHeaderDispatchContext.Provider>
    </MobileHeaderContext.Provider>
  )
}

/**
 * Component that dispatches header updates based on provided prop values,
 * useful for dynamic header updates.
 */
export const WithMobileHeader: React.FC<IMobileHeaderContext> = (props) => {
  const dispatch = useContext(MobileHeaderDispatchContext)
  const id: string = useMemo(() => uuidv4(), [])

  useEffect(() => {
    dispatch({
      type: MobileHeaderActionType.Put,
      entry: {
        id,
        ...props,
      },
    })

    return () => {
      dispatch({
        type: MobileHeaderActionType.Delete,
        entry: { id },
      })
    }
  }, [dispatch, props, id])

  return <>{props.children}</>
}

/** Convenience decorator for setting header properties. */
export function withMobileHeader(headerProps: IMobileHeaderContext) {
  return function <T extends {}>(WrappedComponent: React.ComponentType<T>): void {
    const componentType: React.FC<T> = () => (
      <WithMobileHeader {...headerProps}>{WrappedComponent}</WithMobileHeader>
    )
    componentType.displayName = `withMobileHeader(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`
    return componentType as unknown as void
  }
}
