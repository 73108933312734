/**
 * Storyboard plan enrichment of its json plan equivalent.
 */

import { StoryboardScene } from './storyboard-scene'
import { StoryboardStory } from './storyboard-story'
import { StoryboardTask } from './storyboard-task'

import _ from 'lodash'
import { Entity } from '../../entity'
import { EntityType } from '../entity-type'
import { EsprimaParser, ExpressionEvaluator } from '../../../helpers/evaluation'
import { CustomFormulas } from '../../../helpers/formulas'
import { ChangeSetSettings } from './storyboard-plan-model'

export enum PlatformType {
  WEB = 'web',
  MOBILE = 'mobile',
  MOBILE_WEB = 'mobileWeb',
  ALL  = 'all',
}

export class StoryboardPlanEntity extends EntityType {
  protected childDictionary: {}

  private elementTypes = {
    scene: StoryboardScene,
    story: StoryboardStory,
    task: StoryboardTask,
  }

  constructor({ api, data, entity, entitySchema }) {
    super({ api, data, entity, entitySchema })

    this.childDictionary = {}

    this.getChild = this.getChild.bind(this)

    // enrich the data by resolving all the edges
    this.enrich(data)
  }

  public get storiesForCurrUser() {
    return this.getStoriesForCurrUser(this.api.platform)
  }

  public get mobileDependencies() {
    return _.get(this.content, 'dependencies.mobile')
  }

  public changeSetSettings(): ChangeSetSettings[] {
    return this.get('settings.changeSet', [])
  }

  /**
   * Get the stories that matched user persona
   */
  public getStoriesForUser(user: any, platform: PlatformType = PlatformType.ALL, context: any = {}): StoryboardStory[] {
    const stories = this.getStories()

    return stories
      .filter((story) => this.hasPermission(story, user, context))
      .filter((story) => {
        // the absent of `platforms` prop means it is for all platforms
        return platform === PlatformType.ALL
          || !story.platforms
          || _.includes(story.platforms, PlatformType.ALL)
          || _.includes(story.platforms, platform)
      })
  }

  public getStoriesForCurrUser(platform: PlatformType, context: any = {}): StoryboardStory[] {
    const user = this.api.getSettings().user

    return this.getStoriesForUser(user, platform, context)
  }

  public getStories = (storyId = ''): StoryboardStory[] => {
    const stories = this.getChildWithType(StoryboardStory) as StoryboardStory[]

    return _.filter(
      stories,
      (story: StoryboardStory) => _.isEmpty(storyId) || _.isEqual(story.data.id, storyId)
    )
  }

  public getScenes = (storyId = '') => {
    const story = this.getChild(storyId) as StoryboardStory

    return _.defaultTo(story.getScenes(), [])
  }

  public getScenesForCurrUser(platform: PlatformType): any {
    const stories = this.getStoriesForCurrUser(platform)
    const scenes = _.flatMap(stories, (story) => story.getScenes())
    return _.keyBy(scenes, (scene) => scene.data.id)
  }

  public getTasks = (sceneId = ''): StoryboardTask[] => {
    const scene = this.getChild(sceneId) as StoryboardScene

    return _.defaultTo(scene.getTasks(), [])
  }

  public getNameFromPathId(pathId) {
    const path = this.getChild(pathId)
    return path?.name
  }

  public getLastPathElement(pathId) {
    return _.last(_.split(pathId, '.'))
  }

  public getTaskByName = (taskId = ''): StoryboardTask => {
    return this.getChild(taskId) as StoryboardTask
  }

  public getSceneByName = (sceneId = ''): StoryboardScene => {
    return this.getChild(sceneId) as StoryboardScene
  }

  /********************************************************************************/
  /**
   * Check if the user has the permission to this story or not
   *
   * @returns true if the user has the permission to this story
   */
  private hasPermission(story: StoryboardStory, user: Entity, context: any = {}): boolean {
    const storyPermissions = story.data.permissions
    const storyUsers = _.compact(_.map(storyPermissions, (permission) => permission.user))
    const { isCreation } = context

    // check if matching users
    const isAssignedToUser = _.find(
      storyUsers,
      (assignedUser) => assignedUser.entityId === _.get(user, 'uniqueId'))

    return (
      isAssignedToUser ||
      _.find(storyPermissions, (permission) => this.permissionSatisfied(user, permission, context))
    )
  }

  private permissionSatisfied(user: Entity, permission: object[], context: any = {}): boolean {
    const firmId       = _.get(permission, 'firm.entityId', '')
    const userId       = _.get(permission, 'user.entityId', '')
    const positionId   = _.get(permission, 'position.entityId', '')
    const departmentId = _.get(permission, 'department.entityId', '')
    const location     = _.get(permission, 'location', {})
    const predicate    = _.get(permission, 'predicate', {})

    const userFirmId       = _.get(user, 'owner.firm.entityId', '')
    const userUserId       = _.get(user, 'uniqueId', '')
    const userPositionId   = _.get(user, 'user.position.entityId', '')
    const userDepartmentId = _.get(user, 'user.department.entityId', '')

    const settings = this.api.getSettings()
    const expressionContext = {
      settings,
      user,
      userDepartmentId,
      userFirmId,
      userPositionId,
      ...context,
    }

    const hasPermission = this.evaluatePredicate(predicate.expression, expressionContext)

    return (
      (_.isEmpty(firmId) || firmId === userFirmId)
      && (_.isEmpty(userId) || userId === userUserId)
      && (_.isEmpty(positionId) || positionId === userPositionId)
      && (_.isEmpty(departmentId) || departmentId === userDepartmentId)
      && (_.isEmpty(location) || this.locationSatisfied(location))
      && (_.isEmpty(predicate) || hasPermission)
    )
  }

  private locationSatisfied(location: object): boolean {
    // since we already filter the geofencing at the outlayer, storyboard plan, for
    // story geofencing, we don't really need to do it again.  Just assume the present
    // of a location means this condition has satisfied.  Maybe in the future, we could
    // geofencing at the story level also along with plan level, but for now it is at plan level
    // only
    return location !== null
  }

  /**
   * Enrich all the children of the plan.  It will do a two scans
   * 1. First scan, it will just index all the children of the plan
   * 2. Second scan, it will resolve the edge pointer as well with some other enrichments
   */
  private enrich(data): void {
    const stories = _.get(data, 'stories', [])
    const scenes = _.get(data, 'scenes', [])
    const tasks = _.get(data, 'tasks', [])

    /* index all the story elements first, then build out its children and parent */
    this.addChildren(stories, 'story')
    this.addChildren(scenes, 'scene')
    this.addChildren(tasks, 'task')

    // enrichment, set sibling, parent, childrent pointers
    this.enrichAllChildren()
  }

  private addChildren(elements: any[], childName: string) {
    _.forEach(elements, (element) => {
      const ChildType = this.getChildType(childName)
      const childSchema = this.getChildSchema(childName)

      const item = new ChildType(element, childSchema)
      this.addChild(item.data.id, item)
    })
  }

  /**
   * Enrich the storyboard element, set the parent, sibling pointers
   */
  private enrichAllChildren = () => {
    const items = _.values(this.childDictionary)
    _.forEach(items, (item: StoryboardStory | StoryboardScene | StoryboardTask) => {
      item.enrich({ getElement: this.getChild })
    })
  }

  private getChild(key: string) {
    const child = _.get(this.childDictionary, key, null)
    if (child) {
      return child
    } else {
      // fall-back to trying the last path part, in case it's a valid top-level
      // key.
      const lastPathElement = this.getLastPathElement(key)
      return _.get(this.childDictionary, lastPathElement, null)
    }
  }

  private getChildWithType = (type: any) => {
    const items = _.values(this.childDictionary)
    return _.filter(items, (item) => item instanceof type)
  }

  private addChild = (key: string, value: any): void => {
    this.childDictionary[key] = value
  }

  private getChildSchema = (name: string) => {
    return _.get(this.entitySchema, `definitions.${name}`, {})
  }

  private getChildType = (name: string) => {
    return this.elementTypes[name]
  }

  private evaluatePredicate = (expression: string, context = {}) => {
    if (_.isEmpty(expression)) {
      return false
    }

    const ctx = {
      _,
      ...context,
    }
    const valueGetter = (key) => _.get(ctx, key, CustomFormulas[key])

    return ExpressionEvaluator.create()
      .setValueGetter(valueGetter)
      .setASTParser(EsprimaParser)
      .evaluate(expression)

  }
}
