import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { isEmailValid, isPhoneValid, isUUIDValid } from 'shared-libs/helpers/utils'

import apis from 'browser/app/models/apis'
import { ISelectProps } from 'browser/components/atomic-elements/atoms/select'
import { SelectStyleProps } from 'browser/components/atomic-elements/atoms/select/select/interface'
// tslint:disable-next-line:max-line-length
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { Query } from 'shared-libs/models/query'

/**
 * @uiComponent
 */
interface IRecipientsFieldProps extends ISelectProps {
  label?: string
  density?: string
}

interface IRecipientsFieldState {
  contacts: any[]
}

const USER_TYPE = '/1.0/entities/metadata/user.json'
const VALUE_COUNT_COMPUTATION = {
  countEntities: {
    path: 'uniqueId',
    type: 'valueCount',
  },
}

export class RecipientsField extends React.Component<IRecipientsFieldProps, IRecipientsFieldState> {

  public static defaultProps: Partial<IRecipientsFieldProps> = {
    label: 'Recipients',
  }

  private userDataSet: any

  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
    }
  }

  public componentDidMount() {
    const { contacts } = this.state
    const newContacts = _.cloneDeep(contacts)

    this.userDataSet = new EntityDataSource({
      entityType: USER_TYPE,
    }).setOnChange((content) => this.handleOnDatasetChange(content))

    new Query(apis).setComputations(VALUE_COUNT_COMPUTATION)
      .setEntityType(USER_TYPE)
      .setMetadata({ shouldIncludeLeafEntities: false })
      .setDebug({ context: 'input--RecipientsField' })
      .getCollection()
      .find().then((content) => {
        const size = content.computations.countEntities
        this.userDataSet.handleMetadataChange({ size: Math.min(size, 999) })
      })
  }

  public componentWillUnmount() {
    this.userDataSet.dispose()
  }

  public render() {
    return (
      <SelectField
        {...this.props}
        data-debug-id='recipientsField'
        isCreatable={true}
        isValidNewOption={this.handleValidate}
        multi={true}
        noResultsText=''
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        options={this.getOptions()}
        placeholder='Enter names or email addresses or phone numbers'
        promptTextCreator={this.renderPrompt}
        styles={{
          placeholder: (base) => ({
            ...base,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: 'calc(100% - 36px)',
            overflow: 'hidden'
          })
        } as SelectStyleProps}
      />
    )
  }

  private handleOnDatasetChange = (content) => {
    const suggestions =  _.map(content, (user) => {
      const entity = user.data.content
      const email = _.get(entity, 'person.emails[0].value', '')
      if (email) {
        return { label: `${user.data.displayName} (${email})`, value: email }
      }
      const phoneNumber = _.get(entity, 'person.phoneNumbers[0].value.phone', '')
      if (phoneNumber) {
        return { label: `${user.data.displayName} (${phoneNumber})`, value: phoneNumber }
      }
      const userIdentity = _.get(entity, 'user.additionalIdentities[0]', '')
      if (userIdentity) {
        return {
          label: `${user.data.displayName} (${userIdentity.type} id: ${userIdentity.driverUniqueId})`,
          value: entity.uniqueId
        }
      }
    })

    apis.getContacts().then((historicalSuggestions: any[]) => {
      historicalSuggestions = _.map(historicalSuggestions, (item) => {
        const suggestionMatch = _.some(suggestions, { value: item.email })
        if (suggestionMatch) {
          const { label } = _.find(suggestions, { value : item.email })
          _.remove(suggestions, { value: item.email })
          return { label, value: item.email }
        }
        return { label: item.email, value: item.email }
      })
      const contacts = _.filter(_.concat(suggestions, historicalSuggestions), (value) => value !== undefined)
      this.setState({ contacts })
    })
  }

  // TODO(Peter): this is crazy but in the share configuration modal this
  // is possible and multiselect just doesn't show those contacts
  private getOptions = () => {
    const { value } = this.props
    const { contacts } = this.state
    const optionsNotInContacts = []
    _.forEach(value, (contact) => {
      if (!_.find(contacts, { value: contact })) {
        optionsNotInContacts.push({ label: contact, value: contact })
      }
    })
    return contacts.concat(optionsNotInContacts)
  }

  private handleChange = (value, options) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(value, options)
    }
  }

  private handleInputChange = (inputValue) => {
    const { onInputChange } = this.props
    if (onInputChange) {
      onInputChange(inputValue)
    }
  }

  private renderPrompt(label) {
    return `Add recipient "${label}"`
  }

  private handleValidate = (option) => {
    return isEmailValid(option) || isPhoneValid(option) || isUUIDValid(option)
  }
}
