/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * An instance or execution of storyboard plan.
 */
import { Promise as BPromise } from 'bluebird'
import _ from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { Entity, ISaveActionProps, ISaveActionResult } from '../../entity'
import { EntityType } from '../entity-type'
import { Event } from './storyboard-execution-model'
import { Mappings } from './storyboard-plan-model'
import { StoryboardScene } from './storyboard-scene'
import { SharedContext, EventType as SharedContextEvent } from './storyboard-shared-context'
import { StoryboardStory } from './storyboard-story'
import { StoryboardTask } from './storyboard-task'
import { RequestMethod, Store } from '../../../models/store'
import { PlatformType } from './storyboard-plan'
import { getDeviceInfoProvider } from '../../device'
import { jsonCloneDeep } from '../../../helpers/utils'
import * as Logger from '../../../helpers/logger'
import { FormulaRegistry } from '../../../helpers/formulas'

export enum StoryboardExecutionStatus {
  PENDING   = 'Pending',
  ACTIVE    = 'Active',
  PAUSED    = 'Paused',
  CANCELED  = 'Canceled',
  COMPLETED = 'Completed',
}

export enum EventType {
  CREATE = 'create',
  EDIT = 'edit',
  DETAIL = 'detail',
  SHARE = 'share',
  EXTERNAL = 'external',
  LOG = 'log',
  BREADCRUMB = 'breadcrumb',
  ACTION = 'action',
  NOTIFICATION = 'notification',
  COMPUTATION = 'core_storyboard_event_computation',
  SYNTHETIC = 'core_storyboard_event_synthetic',
  CHANGESET = 'core_storyboard_event_changeSet',
}

export interface IStoryboardNode {
  story: StoryboardStory
  scene?: StoryboardScene
  task?: StoryboardTask
}

export interface IEventSource {
  storyId: string
  sceneId: string
  taskId?: string
}

export const crumbIdFromEventSource = (eventSource: IEventSource): string => {
  const { storyId, sceneId, taskId } = eventSource
  const ids = _.compact([storyId, sceneId, taskId])
  return _.join(ids, '.')
}

export const crumbIdFromNode = (node: IStoryboardNode): string => {
  return crumbIdFromEventSource(eventSourceFromNode(node))
}

export const eventSourceFromNode = (node: IStoryboardNode): IEventSource => {
  return {
    storyId: node?.story?.data?.id || '',
    sceneId: node?.scene?.data?.id || '',
    taskId: node?.task?.data?.id || '',
  }
}

export const PATHS = {
  EVENTS: 'core_storyboard_execution.events',
  PLAN: 'core_storyboard_execution.plan',
  DETAILS: 'core_storyboard_execution.details',
}

export class StoryboardExecutionEntity extends EntityType {
  public context: SharedContext
  public storyboardPlan?: Entity

  protected storyboardExecution: any = null
  protected currEvents: Event[] = [] // keep a copy of server events that is synced to sharedcontext

  private subscriptions: any[]
  private locationProvider: () => BPromise<any>

  constructor({ api, data, entity, entitySchema }) {
    super({ api, data, entity, entitySchema })

    this.context = new SharedContext(entity)

    this.subscriptions = [
      this.entity.addListener(Store.RECORD_CHANGED, this.onRefreshHandler.bind(this))
    ]

    // exempt kiosk execution from saves since it needs to be stateless
    if (entity.isKioskExecution) {
      entity.saveBlocked = true
    }

    this.syncExecutionEvents = this.syncExecutionEvents.bind(this)
    this.injectSyntheticEvents = this.injectSyntheticEvents.bind(this)

    // enrich the data
    this.initializeStoryboardExecution()

    // add a pre save action to update the execution events before saving back to the server
    this.entity.addPreSaveAction(
      {
        action: this.syncExecutionEvents,
        name: 'syncExecutionEvents',
      },
      true,
    )

    // TODO: disable synthetic event due to some regression while testing chamberlain
    // inject synthetic event to track of state changes
    this.entity.addPreSaveAction(
      {
        action: this.injectSyntheticEvents,
        name: 'injectSyntheticEvents',
      },
      true,
    )

  }

  public setLocationProvider(locationProvider: () => BPromise<any>): void {
    this.locationProvider = locationProvider
  }

  /**
   * Add event to event list and populate its content to the shared context as well
   */
  public processEvent(event: Event) {
    this.context.addEvent(event)
    this.addEvent(event)
  }

  /**
   * Just add the event into the event list without updating the shared context
   */
  public addEvent(event: Event) {
    if (_.isEmpty(event)) {
      return
    }
    this.currEvents.push(event)
  }

  public get planName() {
    return this.get('plan.displayName', 'Unknown')
  }

  public get planVersion() {
    return _.get(this.storyboardPlan, 'core_storyboard_plan.version.versionId')
  }

  /**
   * All the users who has the permission to see this execution
   */
  public get users(): any[] {
    const permissions = this.get('permissions', [])
    return _.map(permissions, (permission) => permission.user) || []
  }

  public get newEvents(): Event[] {
    const currEvents = this.currEvents
    const origEvents = this.executionEvents

    return _.differenceWith(currEvents, origEvents, this.eventComparator)
  }

  public get storiesForCurrUser() {
    return this.getStoriesForCurrentUser(this.api.platform)
  }

  public getStoriesForCurrentUser(platform: PlatformType) {
    const currUser = this.api.getSettings().user
    // potential users from plan
    const plan = _.get(this.storyboardPlan, 'core_storyboard_plan', {})
    const presetStories = plan?.getStoriesForCurrUser?.(platform) || []

    if (_.isEmpty(plan)) {
      const entityId = this.get('plan')?.entityId
      console.log('***WARNING*** Storyboard plan is nil in getStoriesForCurrentUser')
      Logger.warn('Storyboard_Plan_Not_Found', {
        platform,
        entityId,
        storyboardPlan: this.storyboardPlan
      })
    }

    // users already viewed the execution
    const viewedStories = _.chain(this.users || [])
      .filter((user) => _.get(user, 'user.entityId') === currUser.uniqueId)
      .flatMap((user) => plan?.getStories(user.storyId || ''))
      .filter((story) => _.includes(story.platforms, platform))
      .value()

    return _.uniqBy([...presetStories, ...viewedStories], 'id')
  }

  public updateExecutionState = (outputMappings: Mappings, data = {}, source?: IStoryboardNode) => {
    const coreContext = this.context
    const eventOutputMapping = coreContext ? coreContext.setValueWithMappings(outputMappings, data) : []
    const event = this.createEventWithNode(source, 'action')
    event.outputMappings = eventOutputMapping
    this.executionEvents = [event]
  }

  /********************************************************************************/
  /*
   *  Events
   */
  public createEvent(node: IStoryboardNode, type: any = 'edit', associatedEntities: any[] = []): Event {
    return this.createEventWithNode(node, type, associatedEntities)
  }

  public createEventWithNode(node: IStoryboardNode, type: any = 'edit', associatedEntities: any[] = []): Event {
    const eventSource = eventSourceFromNode(node)
    return this.createEventWithSource(eventSource, type, associatedEntities)
  }

  public createEventWithSource(source: IEventSource, type: any = 'edit', associatedEntities: any[] = []): Event {
    const event: Event = {}
    const settings = this.api.getSettings()

    event.id = uuidv4().toString()
    event.source = source
    event.createdBy = { entityId: settings.getUser().uniqueId }
    const trueTimeOffset = FormulaRegistry.getApplicationContext()?.getTruetimeOffset?.() ?? 0
    event.creationDate = moment().add(trueTimeOffset).toISOString()
    event.eventType = type
    event.associations = _.map(associatedEntities, (entity) => {
      const entityId = _.get(entity, 'uniqueId')
      return { entityId }
    })
    event.deviceInfo = getDeviceInfoProvider().getDeviceInfo(this.planVersion)

    return event
  }

  /********************************************************************************/
  /*
   * Breadcrumb to use for playback purpose
   /


  /**
   * @param node: current scene/task node
   * @param contextMappings: use for debugging purposes to see what local context is being passed into the scene/task
   */
  public dropViewedCrumb(node: IStoryboardNode, contextMappings: Mappings = []) {
    const eventSource = eventSourceFromNode(node)
    const id = crumbIdFromEventSource(eventSource)
    const currState = this.isViewed(id)

    if (currState === false) {
      this.dropCrumb(id, eventSource, { isViewed: true })
      /**
       * TODO (VD-10369): Disable inputMappings capturing for debugging purposes, as the plan
       * could pass a rather large object like itself in the inputMappings. We can revisit
       * this feature once we have a truncation feature in place to ensure size limitations.
       */

      // const currContext = this.context.getValueWithMappings(contextMappings)
      // only capture the inputMappings context on the entry of scene/task, but not on completed
      // since the outputMappings could affect the inputMappings context
      // if (!_.isEmpty(currContext)) {
      //   crumb.inputMappings = [
      //     {
      //       value: currContext
      //     }
      //   ]
      // }
    }
  }

  public dropCompletedCrumb(node: IStoryboardNode) {
    const eventSource = eventSourceFromNode(node)
    const id = crumbIdFromEventSource(eventSource)
    const currState = this.isCompleted(id)

    if (currState === false) {
      this.dropCrumb(id, eventSource, { isCompleted: true })
    }
  }

  public dropCrumb(id: any, eventSource: any, crumb: any): Event {
    const event = this.createEventWithSource(eventSource, EventType.BREADCRUMB)
    event.outputMappings = [
      {
        destination: id,
        value: {
          breadCrumbs: {
            ...crumb,
          },
        },
      },
    ]
    this.processEvent(event)
    return event
  }

  /**
   * Get the storyboard element(scene/task) status from shared context
   */
  public getCrumbs(id: string) {
    return this.context.getValueWithPath(`${id}.breadCrumbs`) || {}
  }

  public isViewed(id: string) {
    const crumbs = this.getCrumbs(id)
    const { isViewed } = crumbs
    return !!isViewed
  }

  public isCompleted(id: string) {
    const crumbs = this.getCrumbs(id)
    const { isCompleted } = crumbs
    return !!isCompleted
  }

  /********************************************************************************/

  private initializeStoryboardExecution() {
    // resolve plan edge
    this.fetchStoryboardPlan().then(() => {
      // init the shared context
      this.initSharedContext()
    })
  }

  private fetchStoryboardPlan() {
    const store = this.api.getStore()
    const storyboardPlan = this.get('plan')

    if (storyboardPlan) {
      return store.getOrFetchRecord(storyboardPlan.entityId).then((entity) => {
        if (entity instanceof Entity) {
          this.storyboardPlan = entity
        } else {
          // when the store is deserialize, it loads all the json into its map table
          // then materializeRecord, create Entity, then save all the Entity records
          // back to the table again.  So if it deserialize the execution before the
          // plan, then the plan is just a pure json and not an Entity object.  Kind
          // of hacky for now
          this.storyboardPlan = new Entity(entity, this.api)
          store.cacheRecord(this.storyboardPlan)
        }
        return this.storyboardPlan
      })
      .catch((e) => {
        console.error('Unable to fetch plan', storyboardPlan.entityId, e)
      })
    }
    return Promise.reject(new Error('No storyboard plan'))
  }

  /**
   * initial the shared context by replay the existing execution events
   */
  private initSharedContext() {
    this.initDefaultSharedContext()

    // populate shared context from execution events
    const events = this.executionEvents
    const sortedEvents = this.sortEvents(events)
    this.context.addEvents(sortedEvents)

    // cached last synced events
    this.currEvents = jsonCloneDeep(events)
  }

  /**
   * Extract the blob from plan and use it as default shared context
   */
  private initDefaultSharedContext() {
    const blobData = _.get(this.storyboardPlan, PATHS.DETAILS, {})
    this.context.setDefault(blobData)
  }

  private get executionEvents(): Event[] {
    return jsonCloneDeep(this.entity.get(PATHS.EVENTS, []))
  }

  private set executionEvents(events: Event[]) {
    const tmpEvents = jsonCloneDeep(events)
    const origEvents = _.get(this.entity.origContent, PATHS.EVENTS, [])

    const newEvents = _.differenceWith(tmpEvents, origEvents, this.eventComparator)
    this.entity.set(PATHS.EVENTS, [...origEvents, ...newEvents])
  }

  private sortEvents(events: Event[]) {
    return events.sort((a: Event, b: Event) => {
      const aDate = Date.parse(a.creationDate)
      const bDate = Date.parse(b.creationDate)

      return aDate < bDate ? -1 : 1
    })
  }

  /**
   * Once the entity is refresh from the server, merge the changes into the shared context
   */
  private onRefreshHandler() {
    // only refresh shared context on complete and not spammy upload progress (0.01% to 0.99%) RECORD_CHANGE
    const shouldRefresh = this.entity.get('status.progress.percent', 1) === 1
    if (shouldRefresh) {
      this.updateSharedContext()
    }
  }

  /**
   * Update shared context with changes from the server
   */
  private updateSharedContext() {
    // need to find the diff to merge the events from server to shared context
    const prevEvents = this.sortEvents(this.currEvents)
    const latestEvents = this.sortEvents(this.executionEvents)

    // need to calculate the outer join and sort them since the event from server
    // could be before local event in which case need to write events from the server
    // first then re-set the latest one from local to prevent out of order events merging
    const duplicatedEvents = _.intersectionWith(prevEvents, latestEvents, this.eventComparator)
    const unsavedEvents = _.differenceWith(prevEvents, duplicatedEvents, this.eventComparator)
    const newEvents = _.differenceWith(latestEvents, duplicatedEvents, this.eventComparator)

    const sortedNewEvents = this.sortEvents([...newEvents])

    // update shared context with the newest event from server
    this.context.addEvents(sortedNewEvents)

    this.currEvents = [...latestEvents, ...unsavedEvents]

    // for the case when the server modified the structure data.  When the structured data is updated
    // directly, it does not append the event so emit event and let the subscriber to check for changes
    this.context.emit(SharedContextEvent.Update, {})
  }

  private eventComparator = (a: Event, b: Event) => {
    return _.isEqual(a.id, b.id)
  }

  private syncExecutionEvents = async (entity) => {

    // backfill the geolocation on save in one shot
    const events = this.newEvents.filter((event) => _.isEmpty(event.createdAt))
    if (this.locationProvider && !_.isEmpty(events)) {
      try {
        const createdAtLocation = await this.locationProvider()
        events.forEach((event) => _.set(event, 'createdAt', createdAtLocation))
      } catch (err) {
        // only best effort to get the location, if the location is not available, just move on
        console.debug('unable to acquire the location', err)
      }
    }

    // update the events with current events
    this.executionEvents = this.currEvents

    return Promise.resolve()
  }

  /**
   * Detect any state changes that are not tracked with any events yet and inject an synthetic event
   * so all the changes are tracked through events log
  *
   * Only run this callback for PUT request.  In theory, it should be run for PATCH as well, but let be conservative for
   * now since PUT request is outside the workflow request
   *
   * TODO: able to detect the state change automatically on the server side as well to inject synthetic event.  In
   * some cases, there are triggers that update the execution state and has to manually track it.  It would be good to
   * have a trigger to detect the changes and auto inject synthetic event.  The trigger should be run at the end of the pipeline
   * and do the following
   * 1. Somehow compare the execution state at very beginning of the pipeline and the state at the end, and the changes
   *    between the start and end are the ones from pipeline on the server side
   * 2. Dry-run and rebuild the execution state from the events
   * 3. Compare the rebuilt execution state and the actual one, the differences are the ones we should generate synthetic events for
   * 4. Generate synthetic events to capture the changes and inject back to the events log
   *
   * The basic idea is to self-heal/repair the events log so it could rebuild the execution state correctly
   *
   * or we could simplify the whole thing and just create a trigger on the server side to ensure the events log
   * is always in sync with the execution state.  If not, then it will inject the synthetic event to make it in sync
   * again
   *
   * Sample injected events
   *
   *        {
   *            "id": "cd4eeacb-0478-434e-9efc-a90850a2012d",
   *            "createdBy": {
   *                "entityId": "af0a7754-e64e-4eb5-b795-6d43da165523",
   *                "displayName": "Vector Support"
   *            },
   *            "eventType": "core_storyboard_event_synthetic",
   *            "creationDate": "2023-01-12T18:56:27.854Z",
   *            "outputMappings": [
   *                {
   *                    "value": {
   *                        "fieldA": "12"
   *                    },
   *                    "destination": "custom_tests_execution_kitchenSink.detailsEdit"
   *                }
   *            ]
   *        },
   *        {
   *            "id": "e66889eb-0c7a-4a3e-b36f-503878804676",
   *            "createdBy": {
   *                "entityId": "af0a7754-e64e-4eb5-b795-6d43da165523",
   *                "displayName": "Vector Support"
   *            },
   *            "eventType": "core_storyboard_event_synthetic",
   *            "creationDate": "2023-01-12T19:03:47.501Z",
   *            "outputMappings": [
   *                {
   *                    "value": "123",
   *                    "destination": "custom_tests_execution_kitchenSink.detailsEdit.fieldA"
   *                }
   *            ]
   *        }
   *
   * @param entity
   * @param props
   */
  private injectSyntheticEvents = async (entity: Entity, props: ISaveActionProps = {}) => {
    // TODO: narrow this to fix one particular use case, edit the execution outside the workflow in the details tab  as described in VD-8784
    // Need to run more tests supporting other use cases
    const isUpdateUsingPUT = RequestMethod.PUT && !entity.isNew
    if (!isUpdateUsingPUT) {
      return {}
    }

    // find the paths of the revelant changes only
    const paths = _.filter(entity.namespaces, (namespace) => namespace !== 'core_storyboard_execution' && namespace !== 'entity')

    // detect the changes
    // most of the properties under core_storyboard_execution are managed by the server except for status which could
    // be overwrite by admin
    const statePatches = entity.jsonPatch(entity.prevContent, entity.content,
      {
        includedPaths: [ ...paths, 'core_storyboard_execution.status']
      })

    // convert execution state changes into synthetic events
    const syntheticEvents = _.map(statePatches, (patch) => this.patchToEvent(patch))

    // convert synthetic events into json patches
    const syntheticPatch = _.map(syntheticEvents, (event) => this.eventToEventsLogPatch(event))

    // also modify the request method to PATCH to avoid any potential overwrite issue caused by PUT
    const { saveProps = {} } = props

    // for the synthetic patch generated from synthetic events, keep in a separate space so it won't tamper with the
    // the organic patch from the actual events
    return {
      ...props,
      method: RequestMethod.PATCH,
      saveProps: {
        ...saveProps,
        syntheticPatch: syntheticPatch
      }
    }
  }

  private patchToEvent(patch: any): Event {
    const { op, path, value } = patch
    // TODO: add json pointer support for output mappings.  Currently, only property accessor dot notation
    // simple conversion from json pointer path to dot notation.  Probably won't cover all cases
    // convert json pointer "/custom_tests_execution_kitchenSink/detailsEdit" to dot "custom_tests_execution_kitchenSink.detailsEdit"
    const dotPath = path.replace(/\//g, '.').slice(1)
    const event = this.createEventWithSource(null, 'core_storyboard_event_synthetic')

    // no need to process this synthetic event on the server side
    event.processedDate = event.creationDate

    event.outputMappings = [
      {
        value,
        destination: dotPath
      }
    ]
    return event
  }

  private eventToEventsLogPatch(event: Event): any {
    return {
      op: 'add',
      path: '/core_storyboard_execution/events/-',
      value: event
    }
  }

}
